export const deliveryMethods = [
  { id: '1', name: 'Paczka pocztowa ekonomiczna' },
  { id: '3', name: 'Paczka pocztowa priorytetowa' },
  { id: '6', name: 'List polecony ekonomiczny' },
  { id: '28', name: 'Allegro Przesyłka Polecona' },
  { id: '50', name: 'Kurier Pocztex' },
  { id: '51', name: 'Kurier Pocztex pobranie' },
  { id: '52', name: 'Odbiór w Punkcie Pocztex' },
  { id: '53', name: 'Odbiór w Punkcie Pocztex pobranie' },
  { id: '54', name: 'Automat Pocztex' },
  { id: '55', name: 'Allegro Kurier Pocztex' },
  { id: '56', name: 'Allegro Kurier Pocztex pobranie' },
  { id: '57', name: 'Allegro Odbiór w Punkcie Pocztex' },
  { id: '58', name: 'Allegro Odbiór w Punkcie Pocztex pobranie' },
  { id: '59', name: 'Allegro Automat Pocztex' },
];
